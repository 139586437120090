<template>
  <nav
    class="navbar"
    :class="[navbarScroll ? 'unscrolled-navbar' : 'scrolled-navbar']"
  >
    <div class="container">
      <router-link
        class="navbar-brand scrollN"
        :class="[navbarScroll ? 'unscrolled-link' : 'scrolled-link']"
        to="/"
      >
        ItsBeenJamied
      </router-link>
      <ul class="nav navbar-nav pull-xs-right">
        <li
          class="nav-item scrollN"
          :class="[navbarScroll ? 'unscrolled-link' : 'scrolled-link']"
        >
          <router-link
            to="/about"
            class="scrollN nav-link"
            :class="[navbarScroll ? 'unscrolled-link' : 'scrolled-link']"
            >Web</router-link
          >
        </li>
        <li
          class="nav-item"
          :class="[navbarScroll ? 'unscrolled-link' : 'scrolled-link']"
        >
          <router-link
            to="/about"
            class="scrollN nav-link"
            :class="[navbarScroll ? 'unscrolled-link' : 'scrolled-link']"
            >About</router-link
          >
        </li>
        <li
          class="nav-item scrollN"
          :class="[navbarScroll ? 'unscrolled-link' : 'scrolled-link']"
        >
          <router-link
            to="/about"
            class="scrollN nav-link"
            :class="[navbarScroll ? 'unscrolled-link' : 'scrolled-link']"
          >
            Blog
          </router-link>
        </li>
        <li
          class="nav-item scrollN"
          :class="[navbarScroll ? 'unscrolled-link' : 'scrolled-link']"
        >
          <!-- <router-link to="/thoughts">Thoughts</router-link> -->
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "TheNavbar",
  created() {
    window.addEventListener("scroll", this.navbarOnScroll);
  },
  unmounted() {
    window.removeEventListner("scroll", this.navbarOnScroll);
  },
  methods: {
    navbarOnScroll() {
      if (window.pageYOffset > 0) {
        if (this.navbarScroll) this.navbarScroll = false;
      } else {
        if (!this.navbarScroll) this.navbarScroll = true;
      }
    },
  },
  data() {
    return {
      navbarScroll: true,
    };
  },
};
</script>

<style scoped>
nav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  transition: height 0.5s, line-height 0.5s;
}
.navbar-nav {
  flex-direction: row;
}
.nav-item {
  margin: 5px;
  padding: 5px;
}
.nav-link {
  color: white;
}
.unscrolled-navbar {
  position: absolute;
}
.scrolled-navbar {
  position: fixed;
  background-color: white;
  color: black;
}

.scrollN {
  font-size: 1rem;
  text-decoration: none;
}
.unscrolled-link {
  color: white;
}
.scrolled-link {
  color: black;
}
</style>
