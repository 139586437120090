<template>
  <TheNavbar />
  <router-view> 
  </router-view>
  <TheFooter />
</template>

<script>
import TheNavbar from '@/components/TheNavbar.vue'
import TheFooter from '@/components/TheFooter.vue'

export default {
  name : 'App',
  components: {
      TheNavbar,
      TheFooter,
    } 
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e52;
}
</style>
